import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer text-dark fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fw-bolder d-flex align-items-center fs-5" }
const _hoisted_6 = {
  key: 0,
  href: "#",
  class: "fw-bold text-right text-muted text-hover-primary fs-7"
}
const _hoisted_7 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_8 = {
  class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_9 = { class: "svg-icon svg-icon-1" }
const _hoisted_10 = {
  key: 0,
  class: "bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentUser.name), 1),
          (_ctx.currentUser.transport)
            ? (_openBlock(), _createElementBlock("a", _hoisted_6, _toDisplayString(_ctx.currentUser.transport.name), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_KTUserMenu)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen007.svg" })
        ]),
        (_ctx.notifications.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}