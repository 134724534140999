const DocMenuConfig = [
  {
    pages: [
      // {
      //   heading: "Tablero",
      //   route: "/tablero",
      //   svgIcon: "/media/icons/duotune/general/gen008.svg",
      //   fontIcon: "bi-app-indicator",
      //   permission: null,
      // },
      {
        sectionTitle: "Día de trabajo",
        route: "/registro",
        svgIcon: "/media/icons/duotune/general/gen014.svg",
        fontIcon: "bi-person",
        permission: "workday",
        sub: [
          {
            heading: "Hoy",
            route: "/registro/hoy",
            permission: "workday.view",
          },
          {
            heading: "Registro histórico",
            svgIcon: "/media/icons/duotune/general/gen021.svg",
            route: "/registro/explorar",
            permission: "workday.history",
          },
        ],
      },
      {
        heading: "Donantes",
        route: "/donantes",
        svgIcon: "/media/icons/duotune/medicine/med005.svg",
        permission: "donor_view",
        fontIcon: "bi-layers",
      },
      {
        heading: "Reclamos",
        route: "/reclamos",
        svgIcon: "/media/icons/duotune/communication/com004.svg",
        permission: "donor_claims.view",
        fontIcon: "bi-layers",
      },
      {
        heading: "Análisis de Potencia",
        route: "/bidones",
        svgIcon: "/media/icons/duotune/medicine/med004.svg",
        fontIcon: "bi-layers",
        permission: "samples_update",
      },
      {
        heading: "Hoja de Ruta",
        route: "/ruta",
        svgIcon: "/media/icons/duotune/maps/map003.svg",
        fontIcon: "bi-layers",
        permission: "roadmap_view",
      },
      {
        heading: "Grupos",
        route: "/grupos",
        svgIcon: "/media/icons/duotune/general/gen025.svg",
        permission: "group.manage",
        fontIcon: "bi-people-fill",
      },
      {
        heading: "Novedades",
        route: "/novedades",
        permission: "news.view",
        svgIcon: "/media/icons/duotune/coding/cod005.svg",
        fontIcon: "bi-people-fill",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Perfiles",
        route: "/perfiles",
        svgIcon: "media/icons/duotone/Communication/Shield-thunder.svg",
        permission: "user_view.all",
        fontIcon: "bi-layers",
      },
      {
        heading: "Usuarios",
        route: "/usuarios",
        svgIcon: "media/icons/duotone/Files/User-folder.svg",
        fontIcon: "bi-layers",
        permission: "user_view.all",
      },
      {
        heading: "Transportistas",
        route: "/transportes",
        svgIcon: "/media/icons/duotune/ecommerce/ecm006.svg",
        fontIcon: "bi-layers",
        permission: "user_view.all",
      },

      {
        heading: "Auditoría",
        route: "/auditoria",
        permission: "audit_view",
        svgIcon: "media/icons/duotone/General/Binocular.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
];

export default DocMenuConfig;
